import { useGraphQL } from '@/data';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommercesStatement, CommercesWrite } from '@/data/commerce/commerce.graphql';
import { InvoiceDuplicate, InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { StatementsRead, StatementWrite } from '@/data/commerce/statement.graphql';
import { ExportClientStatementCSV } from '@/data/management/client.graphql';
import { Statement } from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import idPick from '@/helpers/idPick';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { ClientStatementEmailModal, OpenCommercesEmailModal } from '@/modals/email';
import { getInvoiceBalance } from '@/pages/dashboard/commerce/multiPayments/utils';
import { useLocations } from '@/pages/formSelects/locationSelect';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import { useModal } from '@/providers/modal';
import {
	Client,
	ClientCredit, LineItemValidator,
	MutationCommercesWriteArgs,
	MutationInvoiceDuplicateArgs,
	MutationInvoiceWriteArgs,
	MutationStatementWriteArgs,
	Order,
	QueryCommercesStatementArgs,
	QueryStatementsReadArgs,
} from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import { getBrowserTimezone } from '@/utils/timezone';
import { urlSearchParams } from '@/utils/urlSearchParams';
import {
	AddCircle as AddCircleIcon,
	CallMerge as CallMergeIcon,
	Pageview as PageviewIcon,
	PictureAsPdf as PictureAsPdfIcon,
} from '@mui/icons-material';
import { Divider, Link, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import axios from 'axios';
import { format, isSameMonth, isSameYear, startOfMonth, startOfYear } from 'date-fns';
import saveAs from 'file-saver';
import zip from 'jszip';
import { isEmpty, omit, pick, sortBy, startCase, toLower, uniqBy, upperFirst } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import Actions, { ActionPropsArray } from '../actions';
import { fetchPDFBlob } from '../fileUploading/attachment';

const getClientCreditTotal = ( credits: ClientCredit[] | undefined ) => {
	if ( !credits || isEmpty( credits ) ) return 0;
	return credits.reduce( ( sum, credit ) => {
		if ( !credit.selected ) {
			sum += credit.amount || 0;
		}
		return sum;
	}, 0 );
};

export default function OpenInvoicesDialogActions( {
	variables,
	selectedClient,
	checkedClients,
	selectedStatuses,
	month,
	year,
	selectedTab,
	clientStatement,
	commercesData,
	clientView,
	currentTab,
	customDates,
}: {
	variables: any,
	selectedClient: Client | null,
	checkedClients: Client[],
	selectedStatuses: any,
	month: Date | string | undefined,
	year: Date | string | undefined,
	selectedTab: string,
	clientStatement: boolean,
	commercesData: Order[] | undefined,
	clientView: boolean,
	currentTab?: number | undefined,
	customDates?: Date[]
} ) {
	const { showModal } = useModal();
	const { showMenu } = useMenu();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { staff, user } = useUserInfo();
	const linkRef = useRef<HTMLAnchorElement>( null );
	const [ locations ] = useLocations();
	const confirmDialog = useConfirmDialog();
	const [ clientIds, setClientIds ] = useState<string[]>( [] );
	const timezone = getBrowserTimezone();
	const excludePaidFromStatement = staff?.company?.metadata?.excludePaidFromStatement;
	
	useEffect( () => {
		setClientIds( checkedClients?.length
			? checkedClients.map( ( client ) => client.id )
			: selectedClient?.id ? [ selectedClient.id ] : [] );
	}, [ checkedClients, selectedClient ] );
	
	const multiPdfDownload = async () => {
		enqueueSnackbar( 'Downloading PDF\'s...', { variant: 'info' } );
		const zz = new zip();
		const timezone = staff?.company.metadata?.timezone ?? getBrowserTimezone() ?? 'America/Chicago';
		const pdfPromises = checkedClients.map( async ( client ) => {
			
			let pdfFilters;
			let externalPdfUrl;
			let state = {};
			
			if ( clientView || clientStatement ) {
				state = {
					client       : client?.id,
					clientEmail  : client?.email,
					clientCredits: getClientCreditTotal( client?.clientCredits ),
					type         : selectedTab,
					month,
					year,
					currentTab,
					customDates,
					timezone,
					excludePaidFromStatement,
				};
				pdfFilters = Buffer.from( JSON.stringify( state ) ).toString( 'base64' );
				externalPdfUrl = `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${staff?.company?.id}/clientStatements?${urlSearchParams( {
					timezone,
					staffId: staff?.id,
					s      : pdfFilters,
				} )}`;
			} else {
				state = {
					client     : client?.id,
					clientEmail: client?.id ? undefined : staff?.email,
					statuses   : selectedStatuses,
					type       : selectedTab,
				};
				pdfFilters = Buffer.from( JSON.stringify( state ) ).toString( 'base64' );
				externalPdfUrl = `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${staff?.company?.id}/openCommerces?${urlSearchParams( {
					timezone,
					s: pdfFilters,
				} )}`;
			}
			let name = client.name || client.contact || 'client';
			// remove dot if last character
			if ( name.charAt( name.length - 1 ) === '.' ) {
				name = name.slice( 0, -1 );
			}
			// replace space with dash
			name = name.replace( / /g, '_' );
			try {
				const pdfBlob = await fetchPDFBlob( `/api/proxy?url=${encodeURIComponent( externalPdfUrl )}` );
				zz.file( `${name}.pdf`, pdfBlob );
			} catch {
				return undefined;
			}
			
		} );
		await Promise.all( pdfPromises );
		const date = month ? new Date( month ) : new Date();
		const formattedMonthYear = date.toLocaleDateString( 'en-US', {
			month: 'long',
			year : 'numeric',
		} ).replace( / /g, '-' );
		const blob = await zz.generateAsync( { type: 'blob' } );
		saveAs( blob, `statements-${formattedMonthYear}.zip`, { autoBom: false } );
	};
	
	const { data: statementsData } = useGraphQL<QueryStatementsReadArgs>(
		{
			query    : StatementsRead,
			queryKey : [ 'statementsRead' ],
			variables: {
				options: {
					limit : 100,
					filter: {
						client: { $in: clientIds },
						month : selectedTab === 'month' ? { $ne: null } : null,
						year  : selectedTab === 'year' ? { $ne: null } : null,
					},
				},
			},
		},
		{ enabled: Boolean( clientIds ) && Boolean( clientStatement ) && currentTab === 0 && !clientView && Boolean( selectedTab ) },
	);
	
	const statements = statementsData?.statementsRead?.items;
	
	const hasStatement = useMemo( () => {
		if ( !month && !year || currentTab === 1 || !statements?.length ) return false;
		if ( selectedTab === 'month' && month ) {
			return Boolean( statements?.find( ( statement ) => isSameMonth( new Date( statement.month ), new Date( month ) ) ) );
		}
		if ( selectedTab === 'year' && year ) {
			return Boolean( statements?.find( ( statement ) => isSameYear( new Date( statement.year ), new Date( year ) ) ) );
		}
	}, [ statements, month, year, selectedTab ] );
	
	const statementsMonths = !isEmpty( statements )
		? uniqBy(
			sortBy( statements, [ ( s ) => s.createdAt ] )
				?.reverse()
				?.reduce(
					( arr: Statement[], statement: Statement ) => {
						if ( statement?.month ) {
							const month = format( new Date( statement.month ), 'MMMM yyyy' );
							if ( !arr.find( ( st ) => st?.month === month ) ) {
								arr = [ ...arr, { ...statement, month: month } ];
							}
						}
						return arr;
					},
					[] as Statement[],
				)
				.filter( ( statement ): statement is Statement => Boolean( statement ) ),
			( statement ) => statement.month,
		)
		: [];
	
	let items: ActionPropsArray = [];
	if ( checkedClients?.length <= 1 && selectedClient || clientView ) {
		items = [
			/*!clientView
			&& clientStatement
			&& currentTab === 0
			&& variables
			&& selectedClient
			&& month
			&& commercesData?.find( ( commerce ) => commerce.status !== 'PAID' && isSameMonth( commerce.standingDate || commerce.createdAt, new Date( month ) ) ) && {
				name       : 'One Bill Statement',
				icon       : <CallMergeIcon/>,
				buttonProps: { variant: 'outlined', color: 'primary' },
				onClick    : ( e ) => {
					showMenu( ( { closeMenu } ) => (
						<MenuList sx={{ p: 0, minWidth: 120 }}>
							<MenuItem>
								<ListItemIcon>
									<AddCircleIcon/>
								</ListItemIcon>
								<ListItemText
									secondary='Create an invoice'
									onClick={async () => {
										const { commercesStatement } = await queryGraphQL<QueryCommercesStatementArgs>( {
											query    : CommercesStatement,
											variables: {
												options : { limit: 100, filter: { timezone } },
												clientId: selectedClient.id,
												month   : selectedTab === 'month' ? month : undefined,
												year    : selectedTab === 'year' ? year : undefined,
												excludePaidFromStatement,
												staffId : staff?.id,
											},
										} );
										if ( ( commercesStatement?.count || 0 ) > 50 ) {
											enqueueSnackbar( 'Cannot generate statement for more than 50 documents.', { variant: 'error' } );
											return;
										}
										
										enqueueSnackbar( 'Please wait... The Statement is being generated.', { variant: 'info' } );
										
										const statementCommerces = commercesStatement?.items;
										
										if ( !isEmpty( statementCommerces ) ) {
											const ids = statementCommerces.map( ( { id } ) => id );
											const invoiceLineItems: LineItemValidator[] = [];
											
											for ( const commerce of statementCommerces ) {
												const date = commerce.standingDate || commerce.serviceDate || commerce.updatedAt;
												const type = `${upperFirst( toLower( commerce.type ) )}`;
												const price: number = getInvoiceBalance( commerce );
												
												invoiceLineItems.push( {
													name       : `${type} #${commerce.externalId || commerce.number}`,
													tax        : 0,
													price      : commerce.status === 'PAID' ? 0 : price,
													quantity   : 1,
													unit       : type,
													description: `${safeFormatInTimeZone( date || new Date(),
														'PP',
													)} | ${startCase( toLower( commerce.status ) )}: ${currencyFormat(
														commerce.paidTotal || 0,
													)}`,
													prices: commerce.prices,
												} );
											}
											
											const { invoiceDuplicate } = await mutateGraphQL<MutationInvoiceDuplicateArgs>( {
												mutation : InvoiceDuplicate,
												variables: { ids },
											} );
											
											const { invoiceWrite } = await mutateGraphQL<MutationInvoiceWriteArgs>( {
												mutation : InvoiceWrite,
												variables: {
													id          : invoiceDuplicate.id,
													customNumber: Boolean( staff?.company?.metadata?.customNumber ),
													method      : 'Statement For',
													input       : {
														type    : 'STATEMENT',
														metadata: {
															...invoiceDuplicate.metadata,
															cardFee           : false,
															cardInfo          : {},
															mergedTo          : null,
															mergedFrom        : true,
															paymentByInfo     : null,
															paymentSignatures : null,
															paymentNote       : null,
															enableCashDiscount: null,
															signatureLine     : staff?.company?.metadata?.signatureLine,
															sendReminder      : staff?.company?.metadata?.dueReminder,
															documentLayout    : {
																color: {
																	dark : '#424074',
																	light: '#b1adf4',
																},
																template      : 'default',
																lineItemLayout: 'industrial',
															},
														},
														prices   : [],
														lineItems: invoiceLineItems.map( ( lineItem ) => ( {
															...pick( lineItem, [
																'name',
																'price',
																'quantity',
																'tax',
																'unit',
															] ),
															description: lineItem.description?.trim(),
															isRevenue  : false,
															prices     : lineItem.prices?.map( ( price ) =>
																pick( price, [
																	'name',
																	'isPercent',
																	'value',
																	'quantity',
																] ),
															),
														} ) ),
													},
												},
											} );
											if ( !hasStatement ) {
												await mutateGraphQL<MutationStatementWriteArgs>( {
													mutation : StatementWrite,
													variables: {
														method: 'Statement Invoice',
														input : {
															status: 'DRAFT',
															number: invoiceWrite.number,
															client: selectedClient.id,
															month : selectedTab === 'month'
																? startOfMonth( new Date( month ) )
																: null,
															year: selectedTab === 'year' && year
																? startOfYear( new Date( year ) )
																: null,
															staff   : staff?.id,
															metadata: { invoiceId: invoiceDuplicate.id },
														},
													},
												} );
											}
											
											await mutateGraphQL<MutationCommercesWriteArgs>( {
												mutation : CommercesWrite,
												variables: {
													inputs: statementCommerces.map( ( commerce ) => ( {
														id         : validate( commerce.id ) ? commerce.id : undefined,
														serviceDate: commerce.serviceDate || commerce.updatedAt,
														metadata   : { statementTo: invoiceDuplicate.id },
													} ) ),
												},
											} );
											
											const unpaidCount = statementCommerces.filter(
												( commerce ) => commerce.status !== 'PAID',
											)?.length || 1;
											let gateway: string | undefined
												    = invoiceDuplicate.companyLocation?.gateway?.external === 'CLOVER'
												    	? invoiceDuplicate.companyLocation?.gateway?.id
												    	: '';
											if ( !gateway ) {
												gateway = locations?.find(
													( location ) => location.gateway?.external === 'CLOVER' && location.gateway.active,
												)?.gateway?.id;
											}
											if ( gateway ) {
												await postCloverMeteredBilling( {
													orderId    : invoiceDuplicate.id,
													gatewayId  : gateway,
													eventType  : 'Invoice Statement',
													key        : 'HAOneBillStatement',
													staffId    : staff?.id,
													clientId   : invoiceDuplicate.client?.id,
													entityCount: unpaidCount || 1,
												} ).catch( () => null );
											}
											
											window.open( `/dashboard/commerce/invoices/${invoiceDuplicate.id}/edit`, '_blank' );
										}
										closeMenu();
									}}>
									Create an Invoice
								</ListItemText>
							</MenuItem>
							<MenuItem>
								<ListItemIcon>
									<PageviewIcon/>
								</ListItemIcon>
								<ListItemText secondary='Saved card on file'>Create and Charge</ListItemText>
							</MenuItem>
							<Divider/>
							{!isEmpty( statementsMonths ) ? statementsMonths.map( ( statement, index ) => (
								<MenuItem
									key={index}
									onClick={() => {
										if ( statement.metadata?.invoiceId ) {
											window.open( `/dashboard/commerce/invoices/${statement.metadata.invoiceId}` );
										}
									}}>
									<ListItemIcon>
										<PageviewIcon/>
									</ListItemIcon>
									<ListItemText
										secondary={`Created on ${format( new Date( statement.createdAt ), 'MMMM dd, yyyy' )}`}>
										{statement.month}
									</ListItemText>
								</MenuItem>
							) ) : null}
						</MenuList>
					), e.currentTarget );
				},
			},*/ !clientView && selectedClient && {
				name: clientStatement
					? currentTab === 1 ? t( 'common:email' ) : `${t( 'common:email' )} ${selectedTab}ly`
					: `${t( 'common:email' )} ${selectedTab}`,
				details    : `Email ${selectedClient?.email || ''}`,
				buttonProps: { variant: 'outlined', color: 'primary' },
				disabled   : !variables,
				onClick    : () => {
					if ( clientStatement ) {
						return showModal( ClientStatementEmailModal, undefined, {
							month      : currentTab === 0 && selectedTab === 'month' ? month : undefined,
							year       : currentTab === 0 && selectedTab === 'year' ? year : undefined,
							type       : currentTab === 1 ? '' : selectedTab,
							currentTab,
							customDates: currentTab === 1 && customDates ? customDates : undefined,
							client     : selectedClient,
						} );
					} else {
						return showModal( OpenCommercesEmailModal, undefined, {
							statuses: selectedStatuses,
							type    : selectedTab,
							client  : selectedClient,
						} );
					}
				},
			}, {
				name: clientStatement || clientView
					? currentTab === 1 ? t( 'common:pdf' ) : `${t( 'common:pdf' )} ${selectedTab}ly`
					: `${t( 'common:pdf' )} ${selectedTab}`,
				disabled   : !clientView && !variables,
				buttonProps: { variant: 'outlined', color: 'primary' },
				onClick    : () => {
					if ( clientView || clientStatement ) {
						const state = {
							client       : selectedClient?.id || null,
							clientEmail  : clientView ? user?.email : null,
							clientCredits: getClientCreditTotal( selectedClient?.clientCredits ),
							type         : selectedTab,
							month        : currentTab === 0 && selectedTab === 'month' ? month : undefined,
							year         : currentTab === 0 && selectedTab === 'year' ? year : undefined,
							currentTab,
							customDates  : currentTab === 1 && customDates ? customDates : undefined,
							excludePaidFromStatement,
						};
						
						const pdfFilters = Buffer.from( JSON.stringify( state ) ).toString( 'base64' );
						if ( pdfFilters ) {
							window.open(
								`/api/preview/pdf/${staff?.company?.id || 'aaa'}/clientStatements?${urlSearchParams( {
									timezone,
									staffId: staff?.id,
									s      : pdfFilters,
								} )}`,
								'_blank',
							);
						}
					} else {
						const state = {
							client     : selectedClient?.id,
							clientEmail: selectedClient?.id ? undefined : staff?.email,
							statuses   : selectedStatuses,
							type       : selectedTab,
						};
						
						const pdfFilters = Buffer.from( JSON.stringify( state ) ).toString( 'base64' );
						if ( pdfFilters ) {
							window.open(
								`/api/preview/pdf/${staff?.company?.id}/openCommerces?${urlSearchParams( { s: pdfFilters } )}`,
								'_blank',
							);
						}
					}
				},
			},
		] as ActionPropsArray;
	} else if ( checkedClients?.length > 1 ) {
		items = [
			{
				name   : 'PDF',
				icon   : <PictureAsPdfIcon/>,
				onClick: () => {
					const clientIds = checkedClients.map( ( ha ) => ha.id );
					
					const pdfFilters = Buffer.from( JSON.stringify( {
						month,
						clientIds,
						year,
						timezone,
						currentTab,
						customDates,
						excludePaidFromStatement,
					} ) ).toString( 'base64' );
					if ( pdfFilters ) {
						window.open(
							`/api/preview/pdf/${staff?.company.id}/clientStatementReport?${urlSearchParams( {
								timezone,
								s: pdfFilters,
							} )}`,
							'_blank',
						);
					}
				},
			}, {
				name   : 'CSV',
				onClick: async () => {
					const clientIds = checkedClients.map( ( ha ) => ha.id );
					const { clientStatementCSVString } = await queryGraphQL( {
						query    : ExportClientStatementCSV,
						variables: {
							options: {
								filter: {
									month      : currentTab === 0 ? month : null,
									clientIds,
									year       : currentTab === 0 ? year : null,
									customDates: currentTab === 1 && customDates ? customDates : null,
									timezone,
									excludePaidFromStatement,
								},
							},
						},
					} );
					if ( clientStatementCSVString ) {
						const blob = new Blob( [ clientStatementCSVString ], { type: 'text/csv;charset=utf-8;' } );
						const url = URL.createObjectURL( blob );
						const fixedEncodedURI = url.replace( /#/g, '%23' );
						linkRef.current?.setAttribute?.( 'href', fixedEncodedURI );
						linkRef.current?.setAttribute?.( 'download', 'clients' );
						linkRef.current?.click?.();
					}
				},
			},
			clientStatement && !clientView && {
				name       : 'Email all Statements',
				details    : 'Send emails to each client.',
				disabled   : !month,
				buttonProps: { variant: 'outlined', color: 'primary' },
				onClick    : async () => {
					const value = await confirmDialog( {
						title  : 'Email all statements?',
						message: 'A statement will be sent to each client email.',
					} );
					if ( !value ) return;
					let emailCount = 0;
					let successEmailCount = 0;
					
					for ( const client of checkedClients ) {
						const hasEmail = client?.email;
						if ( !hasEmail ) continue;
						emailCount++;
						try {
							const date = month ? new Date( month ) : new Date();
							const formattedMonthYear = date.toLocaleDateString( 'en-US', { month: 'long', year: 'numeric' } );
							const emailData = {
								month  : selectedTab === 'month' ? month : undefined,
								year   : selectedTab === 'year' ? year : undefined,
								type   : selectedTab,
								currentTab,
								customDates,
								excludePaidFromStatement,
								timezone,
								staffId: staff?.id,
								to     : [ client.email ].filter( Boolean ),
								subject: `Your ${selectedTab}ly statement from ${formattedMonthYear}`,
								note   : `Hi ${client?.contact || client?.name || client?.email || 'there'}, \n${`Here is your ${selectedTab} statement for ${formattedMonthYear}`}`,
							};
							await axios.post( `/api/emails/${client.id}/invoicesStatement`, emailData );
							successEmailCount++;
						} catch ( e ) {
							console.log( e );
						}
					}
					enqueueSnackbar( successEmailCount > 0
						? 'Emails have been sent successfully'
						: emailCount > 0 && !successEmailCount ? 'Emails could not be sent.'
							: !emailCount
								? 'No email addresses were found for the selected clients.' : '', {
						variant: successEmailCount > 0
							? 'success'
							: 'error',
					} );
				},
			}, {
				name       : 'Download all PDFs',
				disabled   : !month,
				buttonProps: { variant: 'outlined', color: 'primary' },
				onClick    : async () => await multiPdfDownload(),
			} ];
	}
	
	return (
		<Fragment>
			<Link ref={linkRef} sx={{ display: 'none' }}>{t( 'common:csv-link' )}</Link>
			<Actions separated items={items}/>
		</Fragment>
	);
	
}

